<template>
  <header class="h-header">
    <div class="logo-image">
      <img src="@/assets/image/h365-logo.png" alt="h365-logo">
    </div>
  </header>
</template>
<script>
export default {
  name: 'HHeader'
}
</script>
<style lang="scss" scoped>
.h-header {
  height: 48px;
  padding: 8px;
  text-align: center;
  .logo-image {
    height: 28px;
    img {
      height: 100%;
    }
  }
}
</style>