<template>
  <div
    v-if="show"
    class="h-snackbar"
  >
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'HSnackbar',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false
    }
  },
  watch: {
    value(data) {
      this.show = data
    },
    show(data) {
      if (data) {
        const t = setTimeout(() => {
          this.$emit('input', false)
          clearTimeout(t)
        }, 3000)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.h-snackbar {
  min-width: 150px;
  background-color: color(red-2);
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 8px;
  position: absolute;
  z-index: 100;
  left: 50%;
  top: 58px;
  transform: translate(-50%, 0%);
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
  box-shadow: 0px 3px 10px #262626;
}

@keyframes fadein {
  from {top: 0; opacity: 0;}
  to {top: 58px; opacity: 1;}
}

@keyframes fadeout {
  from {top: 58px; opacity: 1;}
  to {top: 0; opacity: 0;}
}
</style>