var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-loader",class:{
    'h-loader--overlay': _vm.overlay
  }},[_vm._t("default"),_c('span',{staticClass:"loader",class:{
      'loader--sm': _vm.size === 'sm',
      'loader--lg': _vm.size === 'lg',
      'loader--xl': _vm.size === 'xl'
    }})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }