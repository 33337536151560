import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    coinDetail: null,
    discountInfo: null,
    discountList: null,
    eStoreList: null,
    hideLogo: false,
    applicationUrl: '',
    urlHome: '',
    user: null,
    clientLocation: null
  },
  getters: {
  },
  mutations: {
    setUser(state, data) {
      state.user = data
    },
    setToken(state, token) {
      state.token = token
      this._vm.$cookies.set('h365', token, { sameSite: 'lax' })
    },
    removeToken(state) {
      state.token = ''
      this._vm.$cookies.remove('h365')
    },
    setCoinDetail(state, coinDetail) {
      state.coinDetail = coinDetail
    },
    setHidePlatformInfo(state, serviceId) {
      if (
        process.env.VUE_APP_HIDE_PLATFORM_INFO_SERVICE_ID &&
        serviceId &&
        process.env.VUE_APP_HIDE_PLATFORM_INFO_SERVICE_ID.includes(serviceId)
      ) this._vm.$cookies.set('hidePlatformInfo', true, { sameSite: 'lax' })
      state.hidePlatformInfo = this._vm.$cookies.get('hidePlatformInfo') || false
    },
    setDiscountInfo(state, data) {
      state.discountInfo = data
    },
    setDiscountList(state, data) {
      state.discountList = data
    },
    setEStoreList(state, data) {
      state.eStoreList = data
    },
    setHideLogo(state, data) {
      state.hideLogo = JSON.parse(data) === true
    },
    setApplicationUrl(state, data) {
      state.applicationUrl = data
    },
    setUrlHome(state, data) {
      state.urlHome = data
    },
    setClientLocation(state, data) {
      state.clientLocation = data
    }
  },
  actions: {
  },
  modules: {
  }
})
