import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'
import MessagePlugin from '@/plugin/message'
import '@/plugin/matomo'
import '@/utils/validate.js'
import('@/assets/css/main.scss')

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import {
  faChevronLeft,
  faChevronRight,
  faCreditCard,
  faInfoCircle,
  faArrowRight,
  faXmark,
  faCircleExclamation,
  faCircleCheck,
  faThumbsUp,
  faCheckCircle,
  faHouse,
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons'

import {
  faCommentDots
} from '@fortawesome/free-regular-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* add icons to the library */
library.add(
  faChevronLeft,
  faChevronRight,
  faCreditCard,
  faCheckCircle,
  faInfoCircle,
  faArrowRight,
  faXmark,
  faCircleXmark,
  faCircleExclamation,
  faCircleCheck,
  faThumbsUp,
  faHouse,
  faCommentDots
)

/* add font awesome icon component */
Vue.component('fontIcon', FontAwesomeIcon)

Vue.use(VueAxios, axios)
Vue.use(VueCookies)
Vue.use(MessagePlugin)

Vue.config.productionTip = false
const observableGlobalState = Vue.observable({
  globalState: {
    routeInitialize: false
  }
})
Object.defineProperty(Vue.prototype, '$globalState', {
  get() {
    return observableGlobalState.globalState
  },
  set(value) {
    observableGlobalState.globalState = value
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')