<template>
  <a
    v-if="href"
    class="h-button"
    :disabled="disabled"
    :class="classes"
    :href="url"
    :target="target"
  >
    <slot/>
  </a>
  <router-link
    v-else-if="to"
    class="h-button"
    :class="classes"
    :to="url"
  >
    <slot/>
  </router-link>
  <button
    v-else
    class="h-button"
    :disabled="disabled"
    :class="classes"
    @click="e => $emit('click', e)"
  >
    <slot/>
  </button>
</template>
<script>
const qs = require('qs')
export default {
  name: 'HButton',
  props: {
    type: {
      type: String,
      default: 'primary'
    },
    block: {
      type: Boolean,
      default: false
    },
    shape: {
      type: String,
      default: 'default'
    },
    size: {
      type: String,
      default: 'middle'
    },
    href: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    to: {
      type: String,
      default: ''
    },
    target: {
      type: String,
      default: '_self'
    }
  },
  computed: {
    url() {
      if (this.href) {
        const queryString = qs.stringify(this.$route.query) || ''
        if (this.href.includes('?') && queryString) return `${this.href}&${queryString}`
        else if (queryString) return `${this.href}?${queryString}`
        return this.href
      }
      else if (this.to)
        return { path: this.to, query: this.$route.query}
      return ''
    },
    classes() {
      return {
        'h-button--block': this.block,
        'h-button--round': this.shape === 'round',
        'h-button--large': this.size === 'large',
        'h-button--middle': this.size === 'middle',
        'h-button--small': this.size === 'small',
        [`h-button-type--${this.type}`]: true,
        'active': this.active === true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.h-button {
  display: inline-flex;
  padding: 8px;
  line-height: 20px;
  cursor: pointer;
  transition: all 0.3s;
  border: none;
  outline: none;
  border-radius: 8px;
  min-width: 60px;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  &:hover {
    background-color: lighten(color(gold-5), 10%)
  }
  &--block {
    display: block;
    width: 100%;
  }
  &--round {
    border-radius: 50px;
  }
  &--small {
    padding: 4px 8px;
    line-height: 14px;
  }
  &--middle {
    padding: 8px;
    line-height: 22px;
  }
  &--large {
    font-size: 16px;
    padding: 16px;
    line-height: 24px;

    font-size: 16px;
  }
  &-type {
    &--ghost {
      background-color: transparent;
      &:hover {
        background-color: unset;
        outline: 1px solid;
      }
      &.active {
        background-color: unset;
        outline: 1px solid;
      }
    }
    &--primary {
      background: color(gold-5);
      color: #262626;

      &:hover {
        background-color: lighten(color(gold-5), 10%);
      }
      &.active {
        background-color: color(gold-5);

        &:disabled {
          background-color: lighten(color(gold-5), 30%) !important;
        }
      }
    }
    &--secondary {
      background: white;
      border: 1px solid #D9D9D9;
      color: #595959;

      &:hover {
        background-color: lighten(color(gold-5), 10%);
      }
      &.active {
        background-color: color(gold-5);

        &:disabled {
          background-color: lighten(color(gold-5), 30%) !important;
        }
      }
    }
  }
  &:disabled {
    pointer-events: none;

    border: 1px solid #d9d9d9 !important;
    background-color: #f5f5f5 !important;
    color: hsla(0, 0%, 6%, 0.6) !important;
  }
}

</style>