/**
 * @memo FrankXiao: 修改路徑前須考量h365-platform-api, h365-payment-api 後端是否有寫死前端路由的狀況
 */
export default [
  {
    path: '/payment',
    name: 'payment',
    component: () => import('@/views/payment'),
    children: [
      {
        path: 'createdOrder',
        name: 'payment-create-order',
        meta: {
          title: '支付-選擇支付通道',
          auth: true
        },
        component: () => import('@/views/payment/createdOrder')
      },
      {
        path: 'success',
        name: 'payment-success',
        meta: {
          title: '支付-支付成功頁'
        },
        component: () => import('@/views/payment/success')
      },
      {
        path: 'failed',
        name: 'payment-failed',
        meta: {
          title: '支付-支付失敗頁'
        },
        component: () => import('@/views/payment/failed')
      },
      {
        path: 'inProgress',
        name: 'payment-inprogress',
        meta: {
          title: '支付-支付處理中'
        },
        component: () => import('@/views/payment/inProgress')
      },
      {
        path: 'reLoginTip',
        name: 'payment-relogin',
        meta: {
          title: '支付-提示重新登入'
        },
        component: () => import('@/views/payment/reLoginTip')
      },
      {
        path: 'waitingPage',
        name: 'payment-waiting',
        meta: {
          title: '支付-支付等待頁'
        },
        component: () => import('@/views/payment/waitingPage')
      }
    ]
  }
]