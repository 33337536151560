import axios from 'axios'
import store from '@/store'

const h365PlatformApi = axios.create({
  baseURL: process.env.VUE_APP_H365_PLATFORM_BASE_URL
})

h365PlatformApi.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,
      ...(store.state.token && { Authorization: `Bearer ${store.state.token}` })
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

const clientLocationApi = axios.create({
  baseURL: process.env.VUE_APP_CLIENT_LOCATION_BASE_URL
})

export {
  h365PlatformApi,
  clientLocationApi
}
