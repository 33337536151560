<template>
  <div
    class="h-breadcrumb"
    :class="{
      disabled: disabled
    }"
  >
    <template v-for="(item, index) in paths">
      <span
        class="h-breadcrumb-link"
        :key="`breadcrumb-item-${index}`"
      >
        <router-link v-if="item.to && disabled === false" :to="{ path: item.to, name: item.name, query: item.query }">
          <span v-if="item.text">{{ item.text }}</span>
          <font-icon v-if="item.icon" :icon="item.icon"/>
        </router-link>
        <a v-else-if="item.href && disabled === false" :href="item.href">
          <span v-if="item.text">{{ item.text }}</span>
          <font-icon v-if="item.icon" :icon="item.icon"/>
        </a>
        <span v-else>
          <span v-if="item.text">{{ item.text }}</span>
          <font-icon v-if="item.icon" :icon="item.icon"/>
        </span>
      </span>
      <span v-if="index !== paths.length -1" :key="`breadcrumb-item-slash-${index}`" class="slash h-mr-4 h-ml-4">／</span>
    </template>
    
  </div>
</template>
<script>
export default {
  name: 'HBreadcrumb',
  props: {
    paths: {
      type: Array,
      default: () => {
        return []
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
.h-breadcrumb {
  display: flex;
  align-items: center;
  color: #8c8c8c;
  margin: 12px 0;
  background: #fafafa;

  &.disabled &-link {
    cursor: unset;
    pointer-events: none;
  }

  .slash {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: #8C8C8C;
  }
  &-link {
    cursor: pointer;
    border: none;
    background-color: unset;
    color: #8c8c8c;
    line-height: 22px;
    font-size: 14px;

    &:last-child {
      cursor: unset;
      color: color(black-3);
    }
  }
  a {
    color: inherit;
    text-decoration: inherit;
  }
}
</style>