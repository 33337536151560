<template>
  <div
    class="h-loader"
    :class="{
      'h-loader--overlay': overlay
    }"
  >
    <slot />
    <span
      class="loader"
      :class="{
        'loader--sm': size === 'sm',
        'loader--lg': size === 'lg',
        'loader--xl': size === 'xl'
      }"
    >
    </span>
  </div>
</template>
<script>
export default {
  name: 'HLoader',
  props: {
    size: {
      type: String,
      default: ''
    },
    overlay: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
.h-loader {
  &--overlay {
    background-color: hsla(0, 0%, 0%, 0.6);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1001;
    .loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.loader {
  border: 3px solid color(gold-6);
  border-top: 3px solid transparent;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 2s linear infinite;
  display: inline-block;
  &--sm {
    border: 2px solid color(gold-6);
    border-top: 2px solid transparent;
    width: 12px;
    height: 12px;
  }
  &--lg {
    border: 4px solid color(gold-6);
    border-top: 4px solid transparent;
    width: 32px;
    height: 32px;
  }
  &--xl {
    border: 6px solid color(gold-6);
    border-top: 6px solid transparent;
    width: 48px;
    height: 48px;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>