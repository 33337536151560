import Vue from 'vue'
import VueRouter from 'vue-router'
import IndexPage from '../views/index.vue'
import paymentRoutes from './payment'
import store from '@/store'
import { ERROR_CODE } from '@/constant'
import init from '@/router/init'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    meta: {
      title: '首頁',
      auth: true
    },
    component: IndexPage
  },
  {
    path: '/store',
    name: 'store',
    meta: {
      title: '充值包-選擇充值包',
      auth: true
    },
    component: () => import('@/views/store.vue')
  },
  {
    path: '/redeem',
    name: 'redeem-index',
    meta: {
      title: '點數兌換-選擇點數商',
      auth: true
    },
    component: () => import('@/views/redeem/index.vue')
  },
  {
    path: '/redeem/channel/:id',
    name: 'redeem-serial-number',
    meta: {
      title: '點數兌換-輸入序號',
      auth: true
    },
    component: () => import('@/views/redeem/detail.vue')
  },
  {
    path: '/redeem/sugoi-result',
    name: 'redeem-sugoi-result',
    meta: {
      title: '點數兌換-Sugoi結果頁',
    },
    component: () => import('@/views/redeem/sugoiResult.vue')
  },
  {
    path: '/redeem/mtpay-result',
    name: 'redeem-mtpay-result',
    meta: {
      title: '點數兌換-APPA結果頁',
    },
    component: () => import('@/views/redeem/mtpayResult.vue')
  },
  {
    path: '/redeem/inProgress',
    name: 'redeem-inprogress',
    meta: {
      title: '點數兌換-兌換中等待頁',
      auth: true
    },
    component: () => import('@/views/redeem/loading.vue')
  },
  ...paymentRoutes,
  {
    path: '/error',
    name: 'error',
    meta: {
      title: '錯誤頁',
    },
    component: () => import('@/views/layout/error.vue')
  },
  {
    path: '/success/:type',
    name: 'success',
    component: () => import('@/views/layout/success.vue')
  },
  { 
    path: "*",
    name: 'page-not-found',
    meta: {
      title: '無對應的路由',
    },
    component: () => import('@/views/layout/pageNotFound.vue')
  },
  { 
    path: "/401",
    name: '401',
    meta: {
      title: '401錯誤',
    },
    component: () => import('@/views/layout/unauthorized.vue')
  },
  { 
    path: "/500",
    name: '500',
    meta: {
      title: '500錯誤',
    },
    component: () => import('@/views/layout/exception.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  /**
   * 
   * @returns https://router.vuejs.org/zh/guide/advanced/scroll-behavior.html
   * @param to
   * @param from
   * @param savedPosition
   */
  scrollBehavior: () => {
    return { x: 0, y: 0 } // 始终滚动到顶部
  },
  routes,
})

router.afterEach(() => {
  console.log('[Debug] after each')
})

router.beforeEach(async (to, from, next) => {
  console.log('[Debug] before each')

  // fix ios old version sdk invalid token issue
  if (to?.query?.token && to.query.token.indexOf('?') !== -1) {
    const { token: _token, ...otherQuery } = to.query
    const replaceQuery = 'token=' + String(_token).replaceAll('?', '&')

    // @todo matomo trackEvent url_replace_token_issue
    // console.log(to)
    window?._paq?.push(['trackEvent', 'Accident', 'Router beforeEach', 'Invalid Token in URL Query'])

    return next({
      path: from.fullPath + '?' + replaceQuery,
      replace: true,
      query: otherQuery
    })
  }

  // if init fail go to 500 error page
  if (router.app.$globalState.routeInitialize === false) {
    const initResult = await init(to.query, router.app)
    if (!initResult) {
      // console.log('init fail')
      return next({ name: '500', query: { code: ERROR_CODE.INIT_FAIL, ...to.query }})
    }
  }

  const requireAuth = to.matched.some(record => record.meta.auth)
  if (requireAuth) {
    const token = store.state.token
    const isAuthenticated = !!(token)

    return (isAuthenticated) ? next() : next({ name: '401', query: { ...to.query } })
  }
  else {
    // console.log('before each next()')
    return next()
  }
})

export default router