<template>
  <div class="h-product">
    <div class="h-product-image">
      <img :src="imageUrl" alt="商品图片" @error="handleImageError">
    </div>
    <div class="h-product-content">
      <div class="h-product-title">{{ name }}</div>
      <div class="h-product-coin">{{ coinPayQty }}</div>
      <div class="h-product-serial-number">
        <div class="h-product-serial-number-title">
          {{ `${$t('payment.orderId')}：` }}
        </div>
        <div class="h-product-serial-number-content">
          {{ spendingId }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HProduct',
  props: {
    spendingId: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    coinPayQty: {
      type: [String, Number],
      default: ''
    }
  },
  computed: {
    imageUrl() {
      if (this.isImageError)
        return require('@/assets/image/item-default.png')
      return this.image
    }
  },
  data() {
    return {
      isImageError: false
    }
  },
  methods: {
    handleImageError() {
      this.isImageError = true
    }
  }
}
</script>
<style lang="scss" scoped>
.h-product {
  background-color: #ffffff;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 12px;
  display: flex;
  &-image {
    width: 80px;
    img {
       width: 100%;
    }
  }
  &-content {
    width: calc(100% - 80px);
    padding: 0px 12px;
    line-height: 20px;
    * {
      margin-bottom: 4px;
    }
    :last-child {
      margin-bottom: 0px;
    }
  }
  &-title {
    color: #262626;
    font-weight: bold;
  }
  &-coin {
    color: #595959;
    background: #FFFBE6;
    height: 20px;
    padding: 0px 8px;
    display: inline-flex;
  }
  &-serial-number {
    color: #595959;
  }
}
</style>